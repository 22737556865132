/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getShineStaff = /* GraphQL */ `
  query GetShineStaff($UserId: String!) {
    getShineStaff(UserId: $UserId) {
      UserId
      InBuilding
      Name
      createdAt
      updatedAt
    }
  }
`;
export const listShineStaffs = /* GraphQL */ `
  query ListShineStaffs(
    $UserId: String
    $filter: ModelShineStaffFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listShineStaffs(
      UserId: $UserId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        UserId
        InBuilding
        Name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getShineClients = /* GraphQL */ `
  query GetShineClients($UserId: String!) {
    getShineClients(UserId: $UserId) {
      UserId
      InBuilding
      Name
      createdAt
      updatedAt
    }
  }
`;
export const listShineClients = /* GraphQL */ `
  query ListShineClients(
    $UserId: String
    $filter: ModelShineClientsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listShineClients(
      UserId: $UserId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        UserId
        InBuilding
        Name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getShineVisitors = /* GraphQL */ `
  query GetShineVisitors($UserId: String!) {
    getShineVisitors(UserId: $UserId) {
      UserId
      InBuilding
      Name
      createdAt
      updatedAt
    }
  }
`;
export const listShineVisitors = /* GraphQL */ `
  query ListShineVisitors(
    $UserId: String
    $filter: ModelShineVisitorsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listShineVisitors(
      UserId: $UserId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        UserId
        InBuilding
        Name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getShineAttendance = /* GraphQL */ `
  query GetShineAttendance($Id: String!) {
    getShineAttendance(Id: $Id) {
      Id
      UserId
      Name
      Date
      TimeIn
      TimeOut
      Building
      InBuilding
      UserType
      Company
      Visiting
      ContactNumber
      Email
      expirationUnixTime
      createdAt
      updatedAt
    }
  }
`;
export const listShineAttendances = /* GraphQL */ `
  query ListShineAttendances(
    $Id: String
    $filter: ModelShineAttendanceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listShineAttendances(
      Id: $Id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        Id
        UserId
        Name
        Date
        TimeIn
        TimeOut
        Building
        InBuilding
        UserType
        Company
        Visiting
        ContactNumber
        Email
        expirationUnixTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
