import {API, graphqlOperation} from "aws-amplify";
import {listShineAttendances} from "../graphql/queries";

export const getCurrentUsersInBuilding = (currentBuilding, userType) => {
    let filter = {
        InBuilding: {
            eq: "1"
        },
        Date: {
            eq: new Date().toISOString().split('T')[0]
        },
        UserType: {
            eq: userType
        },
        Building: {
            eq: currentBuilding
        }
    };
    let attendances = API.graphql(graphqlOperation(
        listShineAttendances, {filter: filter, limit: 1000}
    ))
    return attendances
        .then(
            (data) => {
                return data.data.listShineAttendances.items
            }
        );
}

export const checkIfUserSignedInToday = (userId) => {
    let filter = {
        UserId: {
            eq: userId
        },
        Date: {
            eq: new Date().toISOString().split('T')[0]
        }
    }
    let attendances = API.graphql(graphqlOperation(
        listShineAttendances, {filter: filter, limit: 1000}
    ))
    return attendances
        .then(
            (data) => {
                return data.data.listShineAttendances.items
            }
        );
}