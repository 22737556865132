import {QrScanner} from '@yudiel/react-qr-scanner';
import {showNotification, updateNotification} from "@mantine/notifications";
import {checkIfUserSignedInToday} from "../api/Attendance";
import {signInShineUser, signOutUserUsingQrCode} from "../helpers/SignIn";
import {useContext, useState} from "react";
import {SigninContext} from "../contexts/SigninContext";
import {Center} from "@mantine/core";

export default function QrSignin() {
    const {building} = useContext(SigninContext);
    const [currentUser, setCurrentUser] = useState(null);
    const [currentBuilding] = building;
    const handleScan = data => {
        let user;
        console.log("handleScan");
        if (data) {
            console.log(data);
            try {
                user = JSON.parse(data);
                if (currentUser?.UserId === user.UserId) return;
                setCurrentUser(user);
                showNotification({
                    title: 'Please wait',
                    color: 'blue',
                    loading: true,
                    id: 'signInUser',
                    autoClose: false,
                    disallowClose: true,
                    message: 'Reading QR code...',
                });
            } catch (e) {
                console.log("Invalid QR code: ", e);
                updateNotification({
                    title: 'Error',
                    color: 'red',
                    loading: true,
                    id: 'signInUser',
                    autoClose: false,
                    disallowClose: true,
                    message: 'Invalid QR code',
                });
                return;
            }
            checkIfUserSignedInToday(user.UserId)
                .then((userSignIns) => {
                    console.log("userSignIns: ", userSignIns);
                    if (userSignIns?.length === 0) {
                        console.log('User has not signed in today');
                        signInShineUser(user, currentBuilding);
                    } else if(userSignIns[0].InBuilding === "0") {
                        signInShineUser(user, currentBuilding, userSignIns[0]);
                    } else {
                        console.log('User has already signed in today');
                        signOutUserUsingQrCode(user, currentBuilding, userSignIns[0])
                    }
                });
        }
    }
    const handleError = err => {
        console.error(err)
    }
    return (
        <>
            <Center>
                <div
                    style={{
                        margin: 'auto',
                        width: 400,
                    }}
                >
                <QrScanner
                    onDecode={(result => handleScan(result))}
                    constraints={{
                        width: {
                            min: 640,
                            ideal: 720,
                            max: 1920
                        },
                        height: {
                            min: 640,
                            ideal: 720,
                            max: 1080
                        }
                    }}
                />
                </div>
            </Center>
            <Center>
                <p>Selected Building: {currentBuilding} (switch building in menu)</p>
            </Center>
        </>
    );
}