import {Authenticator} from '@aws-amplify/ui-react';
import ShineRouter from "./helpers/ShineRouter";
import ShineAppShell from "./layout/ShineAppShell";

export default function App() {
    return (
        <Authenticator hideSignUp={true}>
            <ShineAppShell>
                <ShineRouter/>
            </ShineAppShell>
        </Authenticator>
    );
}