import {listShineClients, listShineStaffs} from "../graphql/queries";
import {showNotification} from "@mantine/notifications";
import {API, graphqlOperation} from "aws-amplify";

export const getUsersNotInBuilding = (userType) => {
    let listShineUsers;
    let dataString;
    switch (userType) {
        case 'Client':
            listShineUsers = listShineClients;
            dataString = "listShineClients";
            break;
        case 'Staff':
            listShineUsers = listShineStaffs;
            dataString = "listShineStaffs";
            break;
        default:
            showNotification({
                title: 'Error',
                color: 'red',
                message: 'That user has an invalid user type, please edit via ListUsers',
            });
    }
    let filter = {
        InBuilding: {
            eq: "0"
        }
    };
    let usersTemp;
    usersTemp = API.graphql(graphqlOperation(
        listShineUsers, {filter: filter, limit: 1000}
    ))
    return usersTemp
        .then(
            (data) => {
                return data.data[dataString].items
            }
        )
}

export const getUser = (userType, userId) => {
    let listShineUsers;
    let dataString;
    switch (userType) {
        case 'Client':
            listShineUsers = listShineClients;
            dataString = "listShineClients";
            break;
        case 'Staff':
            listShineUsers = listShineStaffs;
            dataString = "listShineStaffs";
            break;
        default:
            return Promise.resolve(null);
    }
    let filter = {
        UserId: {
            eq: userId
        }
    };
    let usersTemp;
    usersTemp = API.graphql(graphqlOperation(
        listShineUsers, {filter: filter}
    ))
    return usersTemp
        .then(
            (data) => {
                return data.data[dataString].items
            }
        )
}