/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createShineStaff = /* GraphQL */ `
  mutation CreateShineStaff(
    $input: CreateShineStaffInput!
    $condition: ModelShineStaffConditionInput
  ) {
    createShineStaff(input: $input, condition: $condition) {
      UserId
      InBuilding
      Name
      createdAt
      updatedAt
    }
  }
`;
export const updateShineStaff = /* GraphQL */ `
  mutation UpdateShineStaff(
    $input: UpdateShineStaffInput!
    $condition: ModelShineStaffConditionInput
  ) {
    updateShineStaff(input: $input, condition: $condition) {
      UserId
      InBuilding
      Name
      createdAt
      updatedAt
    }
  }
`;
export const deleteShineStaff = /* GraphQL */ `
  mutation DeleteShineStaff(
    $input: DeleteShineStaffInput!
    $condition: ModelShineStaffConditionInput
  ) {
    deleteShineStaff(input: $input, condition: $condition) {
      UserId
      InBuilding
      Name
      createdAt
      updatedAt
    }
  }
`;
export const createShineClients = /* GraphQL */ `
  mutation CreateShineClients(
    $input: CreateShineClientsInput!
    $condition: ModelShineClientsConditionInput
  ) {
    createShineClients(input: $input, condition: $condition) {
      UserId
      InBuilding
      Name
      createdAt
      updatedAt
    }
  }
`;
export const updateShineClients = /* GraphQL */ `
  mutation UpdateShineClients(
    $input: UpdateShineClientsInput!
    $condition: ModelShineClientsConditionInput
  ) {
    updateShineClients(input: $input, condition: $condition) {
      UserId
      InBuilding
      Name
      createdAt
      updatedAt
    }
  }
`;
export const deleteShineClients = /* GraphQL */ `
  mutation DeleteShineClients(
    $input: DeleteShineClientsInput!
    $condition: ModelShineClientsConditionInput
  ) {
    deleteShineClients(input: $input, condition: $condition) {
      UserId
      InBuilding
      Name
      createdAt
      updatedAt
    }
  }
`;
export const createShineVisitors = /* GraphQL */ `
  mutation CreateShineVisitors(
    $input: CreateShineVisitorsInput!
    $condition: ModelShineVisitorsConditionInput
  ) {
    createShineVisitors(input: $input, condition: $condition) {
      UserId
      InBuilding
      Name
      createdAt
      updatedAt
    }
  }
`;
export const updateShineVisitors = /* GraphQL */ `
  mutation UpdateShineVisitors(
    $input: UpdateShineVisitorsInput!
    $condition: ModelShineVisitorsConditionInput
  ) {
    updateShineVisitors(input: $input, condition: $condition) {
      UserId
      InBuilding
      Name
      createdAt
      updatedAt
    }
  }
`;
export const deleteShineVisitors = /* GraphQL */ `
  mutation DeleteShineVisitors(
    $input: DeleteShineVisitorsInput!
    $condition: ModelShineVisitorsConditionInput
  ) {
    deleteShineVisitors(input: $input, condition: $condition) {
      UserId
      InBuilding
      Name
      createdAt
      updatedAt
    }
  }
`;
export const createShineAttendance = /* GraphQL */ `
  mutation CreateShineAttendance(
    $input: CreateShineAttendanceInput!
    $condition: ModelShineAttendanceConditionInput
  ) {
    createShineAttendance(input: $input, condition: $condition) {
      Id
      UserId
      Name
      Date
      TimeIn
      TimeOut
      Building
      InBuilding
      UserType
      Company
      Visiting
      ContactNumber
      Email
      expirationUnixTime
      createdAt
      updatedAt
    }
  }
`;
export const updateShineAttendance = /* GraphQL */ `
  mutation UpdateShineAttendance(
    $input: UpdateShineAttendanceInput!
    $condition: ModelShineAttendanceConditionInput
  ) {
    updateShineAttendance(input: $input, condition: $condition) {
      Id
      UserId
      Name
      Date
      TimeIn
      TimeOut
      Building
      InBuilding
      UserType
      Company
      Visiting
      ContactNumber
      Email
      expirationUnixTime
      createdAt
      updatedAt
    }
  }
`;
export const deleteShineAttendance = /* GraphQL */ `
  mutation DeleteShineAttendance(
    $input: DeleteShineAttendanceInput!
    $condition: ModelShineAttendanceConditionInput
  ) {
    deleteShineAttendance(input: $input, condition: $condition) {
      Id
      UserId
      Name
      Date
      TimeIn
      TimeOut
      Building
      InBuilding
      UserType
      Company
      Visiting
      ContactNumber
      Email
      expirationUnixTime
      createdAt
      updatedAt
    }
  }
`;
