import {API, graphqlOperation} from "aws-amplify";
import {
    createShineAttendance,
    createShineClients,
    createShineStaff, deleteShineAttendance,
    deleteShineClients,
    deleteShineStaff, updateShineAttendance, updateShineClients, updateShineStaff
} from "../graphql/mutations";
import {showNotification, updateNotification} from "@mantine/notifications";
import {v4 as uuidv4} from 'uuid';

export const signInShineUser = async (user, currentBuilding, userSignInRecord) => {
    let updateShineUsers;
    let clientId;
    let staffId;
    switch (user.UserType) {
        case 'Client':
            updateShineUsers = updateShineClients;
            clientId = user.UserId;
            break;
        case 'Staff':
            updateShineUsers = updateShineStaff;
            staffId = user.UserId;
            break;
        default:
            showNotification({
                title: 'Error',
                color: 'red',
                message: 'That user has an invalid user type, please edit via ListUsers',
            });
    }
    if (!userSignInRecord) {
        // Create the attendance record
        const attendanceRecord = {
            Id: uuidv4(),
            UserId: user.UserId,
            Name: user.Name,
            Date: new Date().toISOString().split('T')[0],
            TimeIn: new Date().toLocaleTimeString("en-GB", {
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit"
            }),
            Building: currentBuilding,
            UserType: user.UserType,
            InBuilding: "1",
            // 3 months from today
            expirationUnixTime: Math.floor(Date.now() / 1000) + 7948800,
        }
        try {
            await API.graphql(graphqlOperation(
                createShineAttendance, {input: attendanceRecord}
            ));
            // Create the user with the updated InBuilding value
            const userToModify = {
                UserId: user.UserId,
                InBuilding: "1",
            }
            await API.graphql(graphqlOperation(
                updateShineUsers, {input: userToModify}
            ));

            updateNotification({
                title: 'Success',
                color: 'green',
                id: 'signInUser',
                message: 'User signed in successfully',
                autoClose: 5000,
            });
            console.log('User signed in successfully');
        } catch (e) {
            console.log(e);
            updateNotification({
                id: 'signInUser',
                title: 'Error',
                color: 'red',
                message: 'Could not sign in user',
                autoClose: 5000,
            })
        }
    } else {
        // Create the user with the updated InBuilding value
        const userToUpdate = {
            UserId: user.UserId,
            InBuilding: "1",
        }
        await API.graphql(graphqlOperation(
            updateShineUsers, {input: userToUpdate}
        ));
        // Update the attendance record
        const attendanceRecordToUpdate = {
            Id: userSignInRecord.Id,
            TimeOut: " ",
            InBuilding: "1",
        }
        await API.graphql(graphqlOperation(
            updateShineAttendance, {input: attendanceRecordToUpdate}
        ));
        updateNotification({
            title: 'Success',
            color: 'green',
            id: 'signInUser',
            message: 'User signed back in successfully',
            autoClose: 1000,
        });
        console.log("User signed back in successfully");
    }
}

export const signOutUserUsingQrCode = async (user, currentBuilding, attendance) => {
    let updateShineUsers;
    switch (user.UserType) {
        case 'Client':
            updateShineUsers = updateShineClients;
            break;
        case 'Staff':
            updateShineUsers = updateShineStaff;
            break;
        default:
            showNotification({
                title: 'Error',
                color: 'red',
                message: 'That user has an invalid user type, please edit via ListUsers',
            });
    }
    showNotification({
        title: 'Please wait',
        color: 'blue',
        loading: true,
        id: 'signInUser',
        autoClose: false,
        disallowClose: true,
        message: 'Signing out user...',
    });
    // Create the user with the updated InBuilding value
    const userToUpdate = {
        UserId: user.UserId,
        InBuilding: "0",
    }
    await API.graphql(graphqlOperation(
        updateShineUsers, {input: userToUpdate}
    ));
    // Update the attendance record
    const attendanceRecordToUpdate = {
        Id: attendance.Id,
        TimeOut: new Date().toLocaleTimeString("en-GB", {hour: "2-digit", minute: "2-digit", second: "2-digit"}),
        InBuilding: "0",
    }
    await API.graphql(graphqlOperation(
        updateShineAttendance, {input: attendanceRecordToUpdate}
    ));
    updateNotification({
        title: 'Success',
        color: 'green',
        id: 'signInUser',
        message: 'User signed out successfully',
        autoClose: 1000,
    });
    console.log("User signed out successfully");
}