import {useEffect, useState, useContext} from "react";
import {Title, Button, Stack, Tabs, Card, Group, Center} from "@mantine/core";
import {MessageCircle, Photo} from "tabler-icons-react";
import {SigninContext} from "../contexts/SigninContext";
import {showNotification} from "@mantine/notifications";
import {useQuery} from "@tanstack/react-query";
import {getUsersNotInBuilding} from "../api/Users";
import {checkIfUserSignedInToday} from "../api/Attendance";
import {signInShineUser, signOutUserUsingQrCode} from "../helpers/SignIn";

const ManualSignIn = () => {
    const [userType, setUserType] = useState('Client');
    const {building} = useContext(SigninContext);
    const [currentBuilding] = building;
    const users = useQuery({
        queryKey: ['users', userType],
        queryFn: () => getUsersNotInBuilding(userType)
    });
    const signInUser = async (user) => {
        if (user !== null) {
            showNotification({
                title: 'Please wait',
                color: 'blue',
                loading: true,
                id: 'signInUser',
                autoClose: false,
                disallowClose: true,
                message: 'Signing in user...',
            });
            checkIfUserSignedInToday(user.UserId)
                .then((userSignIns) => {
                    user.UserType = userType;
                    if (userSignIns?.length === 0) {
                        console.log('User has not signed in today');
                        console.log(user, currentBuilding);
                        signInShineUser(user, currentBuilding);
                    } else if (userSignIns[0].InBuilding === "0") {
                        signInShineUser(user, currentBuilding, userSignIns[0]);
                    } else {
                        console.log('User has already signed in today');
                    }
                    users.refetch();
                });
        }
    };
    useEffect(() => {
        users.refetch();
    }, [currentBuilding]);
    return (
        <>
            <Title>Sign In</Title>
            <Tabs value={userType} keepMounted={false} onTabChange={setUserType}>
                <Tabs.List>
                    <Tabs.Tab value="Client" icon={<Photo size={14}/>}>Clients</Tabs.Tab>
                    <Tabs.Tab value="Staff" icon={<MessageCircle size={14}/>}>Staff</Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="Client" pt="xs">
                    <Stack>
                        <Group>
                            {users.data?.map((user) => {
                                return (
                                    <Card style={{minWidth: 300}} key={user.UserId}>
                                        <Center>
                                            <Title>{user.Name}</Title>
                                        </Center>
                                        <Center>
                                            <Button onClick={() => {
                                                signInUser(user);
                                            }}>Sign In</Button>
                                        </Center>
                                    </Card>
                                )
                            })}
                        </Group>
                    </Stack>

                </Tabs.Panel>

                <Tabs.Panel value="Staff" pt="xs">
                    <Stack>
                        <Group>
                            {users.data?.map((user) => {
                                return (
                                    <Card style={{minWidth: 300}} key={user.UserId}>
                                        <Center>
                                            <Title>{user.Name}</Title>
                                        </Center>
                                        <Center>
                                            <Button onClick={() => {
                                                signInUser(user);
                                            }}>Sign In</Button>
                                        </Center>
                                    </Card>
                                )
                            })}
                        </Group>
                    </Stack>
                </Tabs.Panel>
            </Tabs>
        </>
    )
}

export default ManualSignIn