import {useNavigate, useParams} from "react-router-dom";
import {Button, Stack, TextInput, Title} from "@mantine/core";
import {useEffect, useState} from "react";
import {API} from "aws-amplify";
import {deleteShineClients, deleteShineStaff, updateShineClients, updateShineStaff} from "../graphql/mutations";
import {QueryClient, useQuery} from "@tanstack/react-query";
import {getUser} from "../api/Users";


const EditUser = () => {
    let { id, userType } = useParams();
    const queryClient = new QueryClient();
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [error, setError] = useState("");
    const user = useQuery({
        queryKey: ['user', id],
        queryFn: () => getUser(userType, id)
    });

    const editUser = async () => {
        let editShineUsers;
        switch (userType) {
            case "Client":
                editShineUsers = updateShineClients;
                break;
            case "Staff":
                editShineUsers = updateShineStaff;
                break;
            default:
                setError("Invalid user type");
                return;
        }
        let userInput = {
            UserId: id,
            Name: name,
        }
        try {
            await API.graphql({
                query: editShineUsers,
                variables: {input: userInput}
            });
            queryClient.invalidateQueries(['user', id]);
            navigate("/users");
        } catch (e) {
            setError("Error editing user");
            console.log(e);
        }

    }

    useEffect(() => {
        if(user.isLoading) return;
        setName(user.data[0]?.Name);
    }, [user.isLoading]);
    useEffect(() => {
        if(user.isLoading) return;
        setName(user.data[0]?.Name);
    }, []);

    const deleteUser = async () => {
        let deleteShineUsers;
        switch (userType) {
            case "Client":
                deleteShineUsers = deleteShineClients;
                break;
            case "Staff":
                deleteShineUsers = deleteShineStaff;
                break;
            default:
                setError("Invalid user type");
                return;
        }
        let userInput = {
            UserId: id,
        }
        try {
            await API.graphql({
                query: deleteShineUsers,
                variables: {input: userInput}
            });
            navigate("/users");
        } catch (e) {
            setError("Error deleting user");
            console.log(e);
        }
    }
    if (user.isLoading) {
        return <p>Loading...</p>;
    }
    return (
        <Stack>
            <Title>Edit User</Title>
            <TextInput label={"Enter a Name"} value={name} onChange={(event) => setName(event.currentTarget.value)}/>
            <Button onClick={() => editUser()}>Edit User</Button>
            <Button onClick={() => deleteUser()}>Delete User</Button>
            {error}
        </Stack>
    );
}

export default EditUser;