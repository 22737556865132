import {AppShell, Burger, Header, MediaQuery, Navbar, SegmentedControl, Text, useMantineTheme} from "@mantine/core";
import {ShineNavbar} from "./Navbar";
import {useContext, useState} from "react";
import {SigninContext} from "../contexts/SigninContext";


const ShineAppShell = (props) => {
    const theme = useMantineTheme();
    const [opened, setOpened] = useState(false);
    const {building} = useContext(SigninContext);
    const [currentBuilding, setCurrentBuilding] = building;
    return (
        <AppShell
            styles={{
                main: {
                    background: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
                },
            }}
            navbarOffsetBreakpoint="sm"
            asideOffsetBreakpoint="sm"
            navbar={
                <Navbar p="md" hiddenBreakpoint="sm" hidden={!opened} width={{sm: 200, lg: 300}}>
                    <SegmentedControl data={['Castle Bailey', 'Pennard']} value={currentBuilding} onChange={setCurrentBuilding}/>
                    <ShineNavbar setOpened={setOpened}/>
                </Navbar>
            }
            header={
                <Header height={{base: 50, md: 70}} p="md">
                    <div style={{display: 'flex', alignItems: 'center', height: '100%'}}>
                        <MediaQuery largerThan="sm" styles={{display: 'none'}}>
                            <Burger
                                opened={opened}
                                onClick={() => setOpened((o) => !o)}
                                size="sm"
                                color={theme.colors.gray[6]}
                                mr="xl"
                            />
                        </MediaQuery>
                        <Text>Shine Cymru Sign In System</Text>
                    </div>
                </Header>
            }
        >
            {props.children}
        </AppShell>
    );
}

export default ShineAppShell;