import {Routes, Route} from "react-router-dom";
import ManualSignIn from "../components/ManualSignIn";
import ManualSignOut from "../components/ManualSignOut";
import CreateUser from "../components/CreateUser";
import UsersInBuilding from "../components/UsersInBuilding";
import ListUsers from "../components/ListUsers";
import EditUser from "../components/EditUser";
import QrSignin from "../components/QrSignin";

const ShineRouter = () => {
    return (
        <div>
            <Routes>
                <Route exact path="/" element={<QrSignin/>}/>
                <Route exact path="/signin" element={<ManualSignIn/>}/>
                <Route exact path="/signout" element={<ManualSignOut/>}/>
                <Route exact path="/createuser" element={<CreateUser/>}/>
                <Route exact path="/usersinbuilding" element={<UsersInBuilding/>}/>
                <Route exact path="/users" element={<ListUsers/>}/>
                <Route path={"/users/:userType/:id"} element={<EditUser/>}/>
                <Route path="*" element={<p>Not Found</p>}/>
            </Routes>
        </div>
    );
}

export default ShineRouter;