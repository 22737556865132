import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Amplify} from 'aws-amplify';
import awsExports from './aws-exports';
import {BrowserRouter} from "react-router-dom";
import {SigninProvider} from "./contexts/SigninContext";
import {MantineProvider} from '@mantine/core';
import {NotificationsProvider} from '@mantine/notifications';
import '@aws-amplify/ui-react/styles.css';
import {
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query'
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";


Amplify.configure(awsExports);
const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <QueryClientProvider client={queryClient}>
                <SigninProvider>
                    <MantineProvider withNormalizeCSS withGlobalStyles>
                        <NotificationsProvider autoClose={4000}>
                            <App/>
                            <ReactQueryDevtools/>
                        </NotificationsProvider>
                    </MantineProvider>
                </SigninProvider>
            </QueryClientProvider>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
