import {useContext, useEffect, useState} from "react";
import {Stack, Table, Tabs, Title} from "@mantine/core";
import {MessageCircle, Photo} from "tabler-icons-react";
import {SigninContext} from "../contexts/SigninContext";
import {useQuery} from "@tanstack/react-query";
import {getCurrentUsersInBuilding} from "../api/Attendance";


const UsersInBuilding = () => {
    const [userType, setUserType] = useState('Client');
    const {building} = useContext(SigninContext);
    const [currentBuilding] = building;
    const attendances = useQuery({
        queryKey: ['attendances', currentBuilding, userType],
        queryFn: () => getCurrentUsersInBuilding(currentBuilding, userType)
    });
    const ths = (
        <tr>
            <th>User Name</th>
            <th>Time In</th>
        </tr>
    );


    useEffect(() => {
        attendances.refetch();
    }, [currentBuilding]);

    return (
        <>
            <Title>Users in Building</Title>
            <Tabs value={userType} keepMounted={false} onTabChange={setUserType}>
                <Tabs.List>
                    <Tabs.Tab value="Client" icon={<Photo size={14}/>}>Clients</Tabs.Tab>
                    <Tabs.Tab value="Staff" icon={<MessageCircle size={14}/>}>Staff</Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="Client" pt="xs">
                    <Stack>
                        <Table>
                            <thead>{ths}</thead>
                            <tbody>
                            {attendances.data === undefined || attendances.data?.length === 0 ?
                                <tr>
                                    <td>No Clients In Building</td>
                                </tr> : attendances.data?.map((user) => (
                                    <tr key={user.UserId}>
                                        <td>{user.Name}</td>
                                        <td>{user.TimeIn}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>

                    </Stack>
                </Tabs.Panel>

                <Tabs.Panel value="Staff" pt="xs">
                    <Stack>
                        <Table>
                            <thead>{ths}</thead>
                            <tbody>
                            {attendances.data === undefined || attendances.data?.length === 0 ?
                                <tr>
                                    <td>No Clients In Building</td>
                                </tr> : attendances.data?.map((user) => (
                                    <tr key={user.UserId}>
                                        <td>{user.Name}</td>
                                        <td>{user.TimeIn}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Stack>
                </Tabs.Panel>
            </Tabs>
        </>
    );
}

export default UsersInBuilding;