import {createStyles} from '@mantine/core';
import {
    Gauge,
    PresentationAnalytics,
    FileAnalytics,
    Adjustments, Camera,
} from 'tabler-icons-react';
import {LinksGroup} from './NavbarLinksGroup';

const data = [
    {label: 'QR Scanner', icon: Camera, link: '/'},
    {label: 'Manual Sign In', icon: Gauge , link: '/signin'},
    {label: 'Manual Sign Out', icon: PresentationAnalytics, link: '/signout'},
    {label: 'Create User', icon: FileAnalytics, 'link': '/createuser'},
    {label: 'Users in Building', icon: Adjustments, link: '/usersinbuilding'},
    {label: 'List Users', icon: Adjustments, link: '/users'}
];

const useStyles = createStyles((theme) => ({
    linksInner: {
        paddingTop: theme.spacing.xl,
        paddingBottom: theme.spacing.xl,
    }
}));

export function ShineNavbar(props) {
    const {classes} = useStyles();
    const links = data.map((item) => <LinksGroup {...item} setNavOpened={props.setOpened} key={item.label}/>);

    return (
        <div className={classes.linksInner}>{links}</div>
    );
}