import {createContext, useState} from "react";


const SigninContext = createContext();

const SigninProvider = ({ children }) => {
    const [building, setBuilding] = useState("Castle Bailey");

    return (
        <SigninContext.Provider
            value={{
                building: [building, setBuilding]
        }}>
            {children}
        </SigninContext.Provider>
    );
}

export { SigninContext, SigninProvider };