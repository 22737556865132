import React, {useEffect, useState} from "react";
import {API, graphqlOperation} from "aws-amplify";
import {listShineStaffs, listShineClients} from "../graphql/queries";
import {Stack, Table, Tabs, Title, Anchor} from "@mantine/core";
import {MessageCircle, Photo} from "tabler-icons-react";
import QRCode from "qrcode.react";
import {useNavigate} from "react-router-dom";


const ListUsers = () => {
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [userType, setUserType] = useState('Client');
    const [rows, setRows] = useState([]);
    const ths = (
        <tr>
            <th>Name</th>
            <th>QR Code</th>
            <th>Controls</th>
        </tr>
    );


    const getUsersQuery = async () => {
        let listShineUsers;
        let dataString;
        switch (userType) {
            case 'Client':
                listShineUsers = listShineClients;
                dataString = "listShineClients";
                break;
            case 'Staff':
                listShineUsers = listShineStaffs;
                dataString = "listShineStaffs";
                break;
            default:
                console.log("Invalid user type");
        }
        let users = await API.graphql(graphqlOperation(
            listShineUsers
        ))
        setUsers(users.data?.[dataString]?.items);
    }

    useEffect(() => {
        getUsersQuery();
    }, []);
    useEffect(() => {
        getUsersQuery();
    }, [userType]);
    useEffect(() => {
        setRows(users.map((user) => (
            <tr key={user.UserId}>
                <td>{user.Name}</td>
                <td><QRCode value={JSON.stringify({
                    UserId: user.UserId,
                    Name: user.Name,
                    UserType: userType,
                    InBuilding: "0",
                })}/></td>
                <Anchor component={"td"} onClick={() => navigate(`/users/${userType}/${user.UserId}/`)}>Edit/Delete</Anchor>
            </tr>
        )));
    }, [users]);
    return (
        <>
            <Title>List Users</Title>
            <Tabs value={userType} keepMounted={false} onTabChange={setUserType}>
                <Tabs.List>
                    <Tabs.Tab value="Client" icon={<Photo size={14}/>}>Clients</Tabs.Tab>
                    <Tabs.Tab value="Staff" icon={<MessageCircle size={14}/>}>Staff</Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="Client" pt="xs">
                    <Stack>
                        {users === undefined || users?.length === 0 ?
                            <h2>No Clients Found</h2> : <Table>
                                <thead>{ths}</thead>
                                <tbody>{rows}</tbody>
                            </Table>
                        }
                    </Stack>
                </Tabs.Panel>

                <Tabs.Panel value="Staff" pt="xs">
                    <Stack>
                        {users === undefined || users?.length === 0 ?
                            <h2>No Staff Found</h2> : <Table>
                                <thead>{ths}</thead>
                                <tbody>{rows}</tbody>
                            </Table>
                        }
                    </Stack>
                </Tabs.Panel>
            </Tabs>
        </>
    );
}

export default ListUsers;