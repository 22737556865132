import {API} from "aws-amplify";
import {useState} from "react";
import {createShineClients, createShineStaff} from "../graphql/mutations";
import {v4 as uuidv4} from 'uuid';
import {Select, Stack, TextInput, Button, Title} from "@mantine/core";
import {showNotification} from "@mantine/notifications";

const CreateUser = () => {
    const [name, setName] = useState("");
    const [userType, setUserType] = useState("");
    const [error, setError] = useState("");
    const userTypes = ["client", "staff"];
    const createUser = async () => {
        let createShineUsers;
        switch (userType) {
            case "client":
                createShineUsers = createShineClients;
                break;
            case "staff":
                createShineUsers = createShineStaff;
                break;
            default:
                setError("Invalid user type");
        }
        let user = {
            UserId: uuidv4(),
            Name: name,
            InBuilding: "0",
        }
        await API.graphql({
            query: createShineUsers,
            variables: {input: user}
        });
        showNotification({
            title: 'Success',
            color: 'blue',
            message: 'User created successfully',
        });
        setName("");
    }
    return (
        <Stack>
            <Title>Create User</Title>
            <TextInput label={"Enter a Name"} value={name} onChange={(event) => setName(event.currentTarget.value)}/>
            <Select label={"Select a User Type"} value={userType} onChange={setUserType} data={userTypes}/>
            <Button onClick={() => createUser()}>Create User</Button>
            {error}
        </Stack>
    );
}

export default CreateUser