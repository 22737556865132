import {useContext, useEffect, useState} from "react";
import {API, graphqlOperation} from "aws-amplify";
import {Title, Button, Stack, Tabs, Card, Group, Center} from "@mantine/core";
import {
    createShineAttendance,
    createShineClients,
    updateShineClients, updateShineStaff, deleteShineClients,
    deleteShineStaff, updateShineAttendance,
} from "../graphql/mutations";
import {MessageCircle, Photo} from "tabler-icons-react";
import {showNotification, updateNotification} from "@mantine/notifications";
import {useQuery} from "@tanstack/react-query";
import {getCurrentUsersInBuilding} from "../api/Attendance";
import {SigninContext} from "../contexts/SigninContext";

const ManualSignOut = () => {
    const [userType, setUserType] = useState('Client');
    const {building} = useContext(SigninContext);
    const [currentBuilding] = building;
    const signOutUser = async (user) => {
        let updateShineUsers;
        switch (userType) {
            case 'Client':
                updateShineUsers = updateShineClients;
                break;
            case 'Staff':
                updateShineUsers = updateShineStaff;
                break;
            default:
                showNotification({
                    title: 'Error',
                    color: 'red',
                    message: 'That user has an invalid user type, please edit via ListUsers',
                });
        }
        showNotification({
            title: 'Please wait',
            color: 'blue',
            loading: true,
            id: 'signOutUser',
            autoClose: false,
            disallowClose: true,
            message: 'Signing out user...',
        });
        let attendanceRecordToModify = attendances.data?.filter((f) => f.UserId === user.UserId)[0];
        // Create the user with the updated InBuilding value
        const userToModify = {
            UserId: attendanceRecordToModify.UserId,
            InBuilding: "0",
        }
        await API.graphql(graphqlOperation(
            updateShineUsers, {input: userToModify}
        ));
        // Update the attendance record
        const attendanceRecord = {
            Id: attendanceRecordToModify.Id,
            TimeOut: new Date().toLocaleTimeString("en-GB", {hour: "2-digit", minute: "2-digit", second: "2-digit"}),
            InBuilding: "0",
        }
        await API.graphql(graphqlOperation(
            updateShineAttendance, {input: attendanceRecord}
        ));
        updateNotification({
            title: 'Success',
            color: 'green',
            id: 'signOutUser',
            message: 'User signed out successfully',
            autoClose: 1000,
        });
        attendances.refetch();
    };
    const attendances = useQuery({
        queryKey: ['attendances', currentBuilding, userType],
        queryFn: () => getCurrentUsersInBuilding(currentBuilding, userType)
    });
    useEffect(() => {
        attendances.refetch();
    }, [currentBuilding]);
    return (
        <>
            <Title>Sign Out</Title>
            <Tabs value={userType} keepMounted={false} onTabChange={setUserType}>
                <Tabs.List>
                    <Tabs.Tab value="Client" icon={<Photo size={14}/>}>Clients</Tabs.Tab>
                    <Tabs.Tab value="Staff" icon={<MessageCircle size={14}/>}>Staff</Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="Client" pt="xs">
                    <Stack>
                        <Group>
                            {attendances.data?.map((user) => {
                                return (
                                    <Card style={{minWidth: 300}} key={user.UserId}>
                                        <Center>
                                            <Title>{user.Name}</Title>
                                        </Center>
                                        <Center>
                                            <Button onClick={() => {
                                                signOutUser(user);
                                            }}>Sign Out</Button>
                                        </Center>
                                    </Card>
                                )
                            })}
                        </Group>
                    </Stack>
                </Tabs.Panel>

                <Tabs.Panel value="Staff" pt="xs">
                    <Stack>
                        <Group>
                            {attendances.data?.map((user) => {
                                return (
                                    <Card style={{minWidth: 300}} key={user.UserId}>
                                        <Center>
                                            <Title>{user.Name}</Title>
                                        </Center>
                                        <Center>
                                            <Button onClick={() => {
                                                signOutUser(user);
                                            }}>Sign Out</Button>
                                        </Center>
                                    </Card>
                                )
                            })}
                        </Group>
                    </Stack>
                </Tabs.Panel>
            </Tabs>
        </>
    )
}

export default ManualSignOut